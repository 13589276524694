<template>
  <section>
    <div class="all-media">
      <div>
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
                lg="6"
                sm="12"
                offset-sm="0"
                offset-md="6"
                offset-lg="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                    @keyup.enter="pageChanged(1)"
                  />
                  <b-button
                    variant="primary"
                    @click="pageChanged(1)"
                  >
                    Search
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-skeleton-table
            v-if="loading"
            :rows="paginationObject.limit"
            :columns="5"
            :table-props="{
              bordered: true,
              striped: true,
              tableClass: 'skeleton-loading-table',
            }"
          />
          <b-table
            v-else
            ref="mediaTable"
            :items="allUserMediaStorage"
            :per-page="paginationObject.limit"
            hover
            selectable
            :select-mode="selectMode"
            responsive
            :fields="tableColumns"
            show-empty
            empty-text="No matching records found"
            class="position-relative"
            @row-selected="onRowSelected"
          >
            <!-- Column: Media -->
            <template #cell(MEDIA)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <div>
                    <b-img
                      class="userMediaImage"
                      :src="getThumbnailOfFile(data.item)"
                    />
                  </div>
                </template>
                <b-link>
                  <span class="text-nowrap">
                    {{ data.item.name }}
                  </span>
                </b-link>
              </b-media>
            </template>

            <!-- Column: Size -->
            <template #cell(SIZE)="data">
              <span class="text-nowrap">
                {{ Math.ceil(data.item.size) }} Mb
              </span>
            </template>

            <!-- Column: Type -->
            <template #cell(TYPE)="data">
              {{ data.item.type }}
            </template>
            <!-- Column: Imported From -->
            <template
              #cell(DURATION)="data"
              class="d-flex justify-content-center align-items-center"
            >
              {{ data.item.duration | convertSecToMin }}
            </template>

            <!-- Column: Actions -->
            <template #cell(ACTIONS)="data">
              <div class="text-nowrap">
                <feather-icon
                  :id="`invoice-row-${data.item.id}-send-icon`"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  icon="TrashIcon"
                  class="cursor-pointer float-center ml-4"
                  size="16"
                  @click="deleteModal(data.item.id, $event)"
                />
              </div>
            </template>
          </b-table>
        </b-card>
        <CustomPagination
          :total="paginationObject.total"
          :per-page="paginationObject.limit"
          :page="paginationObject.offset"
          @perPageChanged="perPageChanged"
          @pageChanged="pageChanged"
        />
      </div>
    </div>
  </section>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BImg,
  BLink,
  BSkeletonTable,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import moment from 'moment'
import { getThumbnailOfFile } from '@/common/global/functions'
import CustomPagination from '@/components/common/CustomPagination.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BImg,
    BLink,
    BSkeletonTable,
  },
  filters: {
    convertSecToMin(value) {
      if (!value) return '--'
      return moment.utc(value * 1000).format('mm:ss')
    },
  },

  data() {
    return {
      loading: false,
      deleteMediaId: null,
      allUserMediaStorage: [],
      searchQuery: '',
      tableColumns: [
        { key: 'MEDIA', thClass: 'pull-left text-left' },
        { key: 'SIZE' },
        { key: 'TYPE' },
        { key: 'DURATION' },
        { key: 'ACTIONS', thClass: 'pull-right text-right' },
      ],
      selectMode: 'single',
      paginationObject: {
        offset: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  mounted() {
    this.getAllMediaStorageOfUser(this.paginationObject)
  },
  methods: {
    getThumbnailOfFile(file) {
      return getThumbnailOfFile(file)
    },
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllMediaStorageOfUser(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllMediaStorageOfUser(this.paginationObject)
    },
    getAllMediaStorageOfUser({ offset, limit }) {
      this.loading = true
      this.$axios
        .get(
          `admin/media/${this.$route.params.userId}?offset=${
            offset - 1
          }&limit=${limit}&search_query=${this.searchQuery}`,
        )
        .then(({ data: { data } }) => {
          this.allUserMediaStorage = data.results
          this.paginationObject.total = data.total
          this.loading = false
        })
        .catch(error => ({ error }))
    },
    addMediaButton() {
      this.$refs['add-media-modal'].show()
    },
    async onRowSelected(items) {
      if (items) {
        const mediaArray = Object.assign([], items)
        await this.$store.dispatch('lightBox/lightBoxData', mediaArray)
      }
      this.$refs.mediaTable.clearSelected()
    },
    deleteModal(id, e) {
      e.preventDefault()
      e.stopPropagation()
      this.deleteMediaId = id
    },
  },
}
</script>

<style lang="scss" scoped>
.all-media {
  .card {
    text-align: center;
  }
  .media-aside {
    margin-right: 0px;
  }
  .media-body {
    display: inherit;
    padding-left: 10px;
  }
}
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.circular_image {
  border-radius: 40px;
  background-color: #eeedfd;
}
.button {
  width: 300px;
  height: auto;
  border-radius: 5px !important;
}
.userMediaImage {
  width: 20px;
  height: 20px;
}
.skeleton-loading-table {
  th,
  td {
    padding: 1rem 2rem !important;
    height: 3.1rem !important;
    .b-skeleton-text {
      height: 1.2rem;
      margin-bottom: 0;
    }
  }
}
</style>
